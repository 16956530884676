
import {defineComponent} from "vue";
import Loader from "@/components/general/loader.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheHeader from "@/components/TheHeader.vue";
import LeftMenu from "@/components/profile/left_menu.vue";
import OrderDetails from "@/components/profile/flight_order_detail.vue";
import OrderDetailsHeader from "@/components/profile/flight_order_detail_header.vue";
import OrderPassengers from "@/components/profile/flight_order_passengers.vue";
import Navigator from "@/components/flight/navigator.vue";
import orderDetailsController from "@/composables/profile_flight_details";

export default defineComponent({
  name: "flight-order-details",
  components: {
    TheFooter,
    Loader,
    TheHeader,
    Navigator,
    LeftMenu,
    OrderDetails,
    OrderDetailsHeader,
    OrderPassengers,
  },
  setup() {
    const {state, loadFlightDetails} = orderDetailsController();
    return {...state, loadFlightDetails};
  },
  created() {
    const orderId = this.$route.params.id;
    this.loadFlightDetails(orderId.toString());
  },
});
