
import {defineComponent} from "vue";
import {FlightOrderStatus} from '@/models/flight_order_status';
import SearchResultItem from "@/components/flight/search_result_item.vue";
import SearchResultMoreItem from "@/components/flight/search_result_more_item.vue";
import orderDetailsController from "@/composables/profile_flight_details";

export default defineComponent({
  name: "flight_order_detail",
  components: {
    SearchResultItem,
    SearchResultMoreItem,
  },
  setup() {
    const {state} = orderDetailsController();
    return {...state};
  },
  data() {
    return {
      showMoreDetails: false,
      paidStatus: FlightOrderStatus.PAID
    }
  },
  methods: {
    priceFormat(price: number): string {
      return price.toLocaleString('uz-UZ', {
        style: 'currency',
        currency: 'UZS',
        minimumFractionDigits: 0
      })
    }
  },
});
