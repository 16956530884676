import {reactive, toRefs, watch} from 'vue';
import {OrderData} from "@/models/stored_data_models";
import {LocalDb} from "@/repository/localdb";
import {OfferService} from "@/services/offer_service";
import {AvioneMessage} from "@/constants/error_messages";
import {ResponseStatusType, StatusEnum} from "@/constants/response_status_type";
import Swal from 'sweetalert2'


const state = reactive({
    alertShow: false,
    alertMessage: '',
    loading: false,
    loadingMessage: '',
    order: {} as OrderData,
})

export default function orderDetailsController() {

    const loadFlightDetails = function (orderId: string) {
        const orders: OrderData[] | null = LocalDb.getMyFlightOrders();
        if (orders != null) {
            const order = orders.find((s) => s.orderId === parseInt(orderId.toString()))
            if (order)
                state.order = order;
        }
    }

    const onVoidOrder = async function (vue:any) {
        state.loading = true;
        state.loadingMessage = vue.$t(AvioneMessage.TICKET_VOIDING);
        await OfferService.voidOrder(state.order.orderId).then((response) => {
            state.loading = false;
            state.loadingMessage = "";
            if (response.status == ResponseStatusType.Ok) {
                if (response.data.status == StatusEnum.Ok) {
                    Swal.fire('', vue.$t(AvioneMessage.PAYMENT_BOOKING_VOID_SUCCESS), 'info').then(() => {
                        window.location.href = "/profile/orders";
                    });
                } else {
                    Swal.fire('', vue.$t(AvioneMessage.PAYMENT_BOOKING_VOID_FAIL), 'error').then(() => {
                        window.location.href = "/profile/orders";
                    });
                }
            }
        })
    }

    return {
        state: toRefs(state),
        loadFlightDetails,
        onVoidOrder
    }
}
