
import { defineComponent } from "vue";
import Alert from "@/components/general/alert_popup.vue";
import { FlightOrderStatus } from "@/models/flight_order_status";
import orderDetailsController from "@/composables/profile_flight_details";
import { AvioneMessage } from "@/constants/error_messages";
import axios from "axios";
import { LocalDb } from "@/repository/localdb";

export default defineComponent({
  name: "flight_order_detail_header",
  components: {
    Alert,
  },
  data() {
    return {
      tokenData: LocalDb.getToken(),
      voidConfirm: false,
      disableDownloadBtn: false,
      paidStatus: FlightOrderStatus.PAID,
      bookedStatus: FlightOrderStatus.BOOKED,
      voidStatus: FlightOrderStatus.VIOD,
      baseUrl: "https://-testrestapi.avione.uz/api/Flight/",
      downloadBtnText: this.$t(AvioneMessage.DOWNLOAD_TICKET_TEXT),
      orderEndTimeSeconds: 0,
    };
  },
  setup() {
    const { state, onVoidOrder } = orderDetailsController();
    return { ...state, onVoidOrder };
  },
  mounted() {
    const { days, hours, minutes } = this.order?.timeTillTicket;
    let totalSeconds = 0;
    if (days > 0) totalSeconds += days * 86400;
    if (hours > 0) totalSeconds += hours * 3600;
    if (minutes > 0) totalSeconds += minutes * 60;
    this.orderEndTimeSeconds = totalSeconds;
  },
  updated() {
    this.voidConfirm = false;
  },
  methods: {
    onVoidClickEvent: function() {
      if (this.order.status == this.bookedStatus) {
        this.alertShow = true;
        this.alertMessage = this.$t(AvioneMessage.PAYMENT_BOOKING_VOID_MESSAGE);
      }
    },
    onAlertCloseClickEventHandler: function() {
      this.alertShow = false;
      this.alertMessage = "";
    },
    onAlertOkClickEventHandler: function() {
      this.alertShow = false;
      this.alertMessage = "";
      this.voidConfirm = true;
      this.onVoidOrder(this);
    },
    async downloadItem(orderId: number) {
      this.disableDownloadBtn = true;
      this.downloadBtnText = this.$t(AvioneMessage.PLEASE_WAIT);
      const response = await axios.get(this.baseUrl + orderId, {
        responseType: "blob",
        headers: { Authorization: "bearer " + this.tokenData?.token },
      });
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "ticket_" + orderId + ".pdf";
      link.click();
      URL.revokeObjectURL(link.href);
      this.downloadBtnText = this.$t(AvioneMessage.DOWNLOAD_TICKET_TEXT);
    },
    // getFormattedEndTime: function(order: any) {},
  },
});
